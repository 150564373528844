import React, {useState, useEffect} from 'react'

import axios from 'axios'
import {  config,  ServerUrl, ImagesUrl,  helpWhatsapp } from './include';
import { useDispatch, useSelector } from 'react-redux';
const Header = ()=>{
  
  const dispatch = useDispatch()
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false,

		schoolInfo:[],
	});
    const [isFull, setIsFull] = useState(false)
/* Get the element you want displayed in fullscreen */ 
var elem = document.documentElement;

const IsFullMode =()=>{
     const windowWidth = window.innerWidth * window.devicePixelRatio;
    const windowHeight = window.innerHeight * window.devicePixelRatio;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    if (((windowWidth/screenWidth)>=0.95) && ((windowHeight/screenHeight)>=0.95)) {
        closeFullscreen()
        return true;
    }
    else {
        openFullscreen()
        return false
    } 
}


/* Function to open fullscreen mode */
function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem = window.top.document.body; //To break out of frame in IE
    elem.msRequestFullscreen();
  }
  setIsFull(true);
}

/* Function to close fullscreen mode */
function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  }
  setIsFull(false);
}

const fetchSchoolInfo = async ()=>{
  const fd = new FormData();
  fd.append('schoolDetails', 'schoolDetails')
  let url = ServerUrl+'/fetch_details'
      const {data} = await axios.post(url, fd, config)
      dispatch({
          type:'SET_RECORDS',
          name:'schoolInfo',
          data:data
      })
}

useEffect(()=>{
   
fetchSchoolInfo()
   },[]);

   const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    return <>        
 <nav className="navbar header-navbar pcoded-header">
    <div className="navbar-wrapper no-print">

        <div className="navbar-logo ">
            <a className="mobile-menu" id="mobile-collapse" href="#!">
                <i className="feather icon-menu"></i></a>
           {/*  <a href="/">
                <img className="img-fluid" style={{width:'180px'}}  src={ImagesUrl+'/about/'+notice.schoolInfo.linelogo} alt="Logo" />
            </a> */}

            <a href="/">
              {schoolInfo.length!==0? <img className="img-fluid" style={{width:'180px'}} src={ImagesUrl+'/about/'+schoolInfo[0].linelogo} alt="Logo" />:[]}
            </a>

            <a href="#!" className="mobile-options">
                <i className="feather icon-more-horizontal"></i>
            </a>
        </div>

        <div className="navbar-container container-fluid ">
            <ul className="nav-left" >
               

                <li>
                <a href="#!" style={{color:'white'}} onClick={!isFull?()=>openFullscreen():()=>IsFullMode()} >
                        <i className={`feather ${!isFull?'icon-maximize':'icon-minimize'} full-screen`} ></i>
                    </a> 
                </li>
            </ul>

           
        </div>
    </div>
</nav>
<div className="whatsapp">
      <a href={'https://api.whatsapp.com/send?phone='+helpWhatsapp} target="_blank" rel='noopener noreferrer'  className="whatsapp-bg text-white"><i className="fa fa-whatsapp text-white text-xl pr-2"></i> WhatsApp Us </a>
    </div> 
    </>
}
export default React.memo(Header)