
const records = {
    schoolClass:[],
    countries:[],
    states:[],
    schoolInfo:[]
}

export const rootReducer =(state = records, action)=>{
    switch(action.type){
        case 'SET_RECORDS':
            return {...state, [action.name]:action.data}
        case 'FETCH_RECORDS':
            return state;
            default:
                return state
    }

}
