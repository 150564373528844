import React, {useState} from 'react';
import Preloader from '../component/preloader'
import Footer from '../component/footer'
import Header from '../component/header'
import Sidebar from './sidebar';

const Template = (props) =>
{
   	
    return (<React.Fragment>
        {/* <!-- Pre-loader start --> */}
        <Preloader />
        {/* <!-- Pre-loader end --> */}


        <div id="pcoded" className="pcoded">

    <div className="pcoded-container">


       {/* Header Component Here */}

       <Header />
{/* The  header End here */}


 <div className="pcoded-main-container">
 <Sidebar menu={props.menu} /> 



                <div className="pcoded-wrapper">
                    <div className="pcoded-content">
                        <div className="pcoded-inner-content">
                          

            {/* <!-- Main-body start --> */}
            {props.children}

        
            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div> 
           {/* The Main Body close here */}            
           </div></div>

            


</div>
            </div> 
        
        
        
    </React.Fragment>)
}
export default React.memo(Template)