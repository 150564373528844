import React from 'react'


const Sidebar = (props)=>{
    return <nav className="pcoded-navbar">
    <div className="pcoded-inner-navbar">
        <ul className="pcoded-item pcoded-right-item">
            
<li className={props.menu==='dashboard'?'active':''}>      <a href="/dashboard">
                    <span className="pcoded-micon"><i className="feather icon-home"></i></span>
                    <span className="pcoded-mtext"> DASHBOARD</span>
                    <span className="pcoded-mcaret"></span>
                </a>  </li>
<li className={props.menu==='application'?'active':''}>       <a href="/application">
                    <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                    <span className="pcoded-mtext">APPLICATIONS</span>
                    <span className="pcoded-mcaret"></span>
                </a> </li>

<li className={props.menu==='password'?'active':''}>       <a href="/change_password">
                    <span className="pcoded-micon"><i className="feather icon-lock"></i></span>
                    <span className="pcoded-mtext">CHANGE PASSWORD</span>
                    <span className="pcoded-mcaret"></span>
                </a> </li>

        </ul>
    </div>
</nav> 
}
export default React.memo(Sidebar)