import React, {useState, useEffect, useRef} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ServerImagesUrl, config, FrontUrl, loginUrl, ImagesUrl } from '../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Header from '../component/header_no_login'

 const ForgotPassword =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		email:'',
		rsMessage:'',
		rsMessageError:'',
		schoolInfo:[]
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!email){
            errors.email ='Please enter valid email address';
			
        }
        
        return errors;
        }
		useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])

        	
	function submit(){  
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/save_controller/tbl_reset_applicant_password';
		   axios.post(url, fd, config)
		   .then(response =>{
		   if(response.data.type==='success'){
			   setUser({...user, rsMessage:response.data.message, email:''})
			   
			   }else{
				setUser({...user, rsMessageError:response.data.message})
				   
			   }    
		   })
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
        })     
}



const fetchSchoolInfo = async ()=>{
        
	const fd = new FormData();
	fd.append('schoolDetails', 'schoolDetails')
	let url = ServerUrl+'/fetch_details'
		const {data} = await axios.post(url, fd, config)
		setUser({...user, schoolInfo:data[0]})
}

useEffect(()=>{  
  fetchSchoolInfo()
},[]);

        return (<>
          <Preloader />
          <div id="pcoded" className="pcoded load-height">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

        <Header />

        </div>

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section  className="with-header">
        <div className="container">

        <div style={{height:'60px'}}>
		</div>

            <div className="row">
                <div className="col-sm-6">
                    



	<div className="auth-box card bg-c-lite-green">
		<div className="card-block">
		
			<div className="card-body">

            <div className='text-white f-w-600'><h4>Did you forget the password to your account?</h4>
			<p className='f-w-600'>
			Then read this guide on how to get a new password. </p><br/>


			<h5>1. Enter your email address in the form provided and  click <b>Reset Password</b></h5>
			<br/>
			<h5>2. An email will be sent to the that Email Address. That email will include a link to change your password.</h5>
			<br/>
			<h5>3. Go to your email account and sign in. When you click the link in the email, you'll be sent to a screen where you'll be asked to enter your new password twice. </h5>
			<br/>
			<h5>The Reset Password link will not be sent if you enter wrong email address.</h5>

			</div>

			</div>
			
		
		</div>
                            </div>

                            </div>
							
                            <div className="col-md-6">
							{user.rsMessage!==''?
				 <div className="alert bg-success mt-3">
				 <p className="icontext"><i className="icon text-success fa fa-check"></i>  {user.rsMessage}</p>
			 </div>:''}

			 {user.rsMessageError!==''?
				 <div className="alert bg-danger mt-3">
				 <p className="icontext"><i className="icon text-danger fa fa-check"></i>  {user.rsMessageError}</p>
			 </div>:''}
	<form className="md-float-material form-material">
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
			
<div className="text-center">
		  <img src={ImagesUrl+'/about/'+user.schoolInfo.schoolLogo} style={{ maxWidth:'150px', maxHeight:'150px' }}  alt="logo.png" />
		  </div>
			
					<h3 className="text-center">Recover your password</h3>
				</div>
			</div>
			<div className="form-group form-primary">

				<input type="email" id="email" name="email" role={'presentation'} autoComplete='off'  value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 

			</div>
				<span className=" form-bar error">{ errors.email}</span>
			<div className="row m-t-30">
				<div className="col-md-12">
					
					<button type="button" onClick={handleSubmit} className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Reset Password</button>
				</div>
			</div>
           <hr/>
			<div className="row">
				<div className="col-md-12">
					<p className="text-inverse text-left">
						
						<a href="/" className='btn btn-inverse'><b className="f-w-600">Create New Account</b></a>
					
						<a href="/applicant_login" className='btn btn-success pull-right'>Back to Login.</a>
					
					</p>
				</div>
			</div>
		</div>
                            </div>
                        </form>



						
						</div>
            </div>
        </div>
    </section>    
    </div>

         
</> 

 );
        
}

export default React.memo(ForgotPassword) 