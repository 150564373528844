import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



export  const mainUrl = process.env.REACT_APP_MAIN_URL
export  const studentUrl= "https://learners."+mainUrl
export  const teacherUrl= "https://staff."+mainUrl
export  const ParentUrl=  "https://parent."+mainUrl
export  const applicantUrl=  "https://forms."+mainUrl
export  const ServerUrl = "https://app."+mainUrl+'/controller'
export  const ImagesUrl = "https://app."+mainUrl+'/images'
export const domain = "."+mainUrl

export const APP_KEY =process.env.REACT_APP_APP_KEY;
export  const LocalUrl = 'localhost'
export  const KAYUS_ULR = 'https://kayus.org'
export const DatabaseName= process.env.REACT_APP_DATABASE_NAME
export const smsAccountUrl = process.env.REACT_APP_SMS_ACCOUNT_URL;


export  const FrontUrl = applicantUrl; 

export const studentName = Cookies.get('appstnm')?CryptoJS.AES.decrypt(Cookies.get('appstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'


export const agentCode =  Cookies.get('appid')?CryptoJS.AES.decrypt(Cookies.get('appid'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('apptk')?CryptoJS.AES.decrypt(Cookies.get('apptk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'


export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'



export const helpEmail = 'enquiry@pageschoolsportal.org'
export const helpMobile = '+234-802-312-1935, 0813 9096 860'

export const helpWhatsapp = '+2348023121935'

export const schoolName = 'PAGE SCHOOLS'
export  const loginUrl = FrontUrl+'/applicant_login';
 




export  const PSKLIVE = process.env.REACT_APP_PSKLIVE; 
export  const PSKTESTPublic = process.env.REACT_APP_PSKTEST;
