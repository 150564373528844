import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import { useParams } from 'react-router'
import axios from 'axios'
import { Alerts, } from '../component/notify'
import {ServerUrl,   Token,  config, agentCode} from '../component/include'
const Application =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
const [content, setContent] = useState([])
const fetchContent =()=>{

    setNotice({...notice, 
        isLoading: true})  
    var sql ="select admissionStatus, email, applicationID, submitted, code, studentName, email, applicationType from tbl_admission_request where agent ='"+agentCode+"' ";

    const fd = new FormData();
    fd.append("sql", sql);

    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{ 
        
        if(result.data.length!==0){
        setContent(result.data)
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{

        setNotice({...notice, 
            isLoading: false})  
    })
    
}

useEffect(()=>{
    fetchContent()
},[]);
    return (  <Template menu='application' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
        <div  className="main-body">
                     <div className="page-wrapper">
                      
                                         <div className="page-body m-t-50">
                                    
                                         <div className="row">

                                       
<div className="col-md-2"></div> 
<div className="col-md-9">
        <div className="card ">
            <div className="card-block">
                   
                   <div className="row">

                   
                

<div className="col-md-12">
                <h3 className="text-center text-inverse">APPLICATIONS</h3>
                </div>

<div className="table-responsive">
                <table className="table table-striped table-bordered  table-full-width">
             

    <thead>
        <tr>
        <th>SN</th>
        <th>Student Name</th>
            <th>Email Address</th>
            <th>Admission Status</th>
            <th>Action</th>

        </tr>
    </thead>
    <tbody>
        
        {content.length!==0? content.map((row, idx)=>
    
    <tr key={idx}>
            <td>{idx+1}</td>
            <td>{row.studentName}</td>
            <td>{row.email}</td>
            <td> <span className={`badge 
        ${row.admissionStatus==='Pending'?'badge-danger':'badge-success'}`}>{row.admissionStatus}</span></td>
            <td>
                {row.submitted==='false'?
                <a href={'/overview/'+row.applicationID+'/'+row.applicationType} className='btn btn-success '> Continue</a>:
                <a href={'/preview_application/'+row.applicationID} className='btn btn-inverse '>Preview</a>
                }
                
            
            </td>
        </tr>): []}
    </tbody> 
</table>
</div>
		
          </div>
            </div>
        </div>
        </div>
{/* 
  <ProfileCard /> */}


                                    </div>


                                   

                    <br/>
                                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(Application) 