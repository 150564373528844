import React, {useEffect} from 'react';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';



import ProtectedView from './protected_view'
import {LicenseTimeout} from './component/notify'
import {  LocalUrl } from './component/include';
import registration from './main/registration';
import forgotpassword from './main/forgotpassword';
import login from './main/login';
import account_verify from './main/account_verify';
import recover_password from './main/recover_password';


const App =()=>{

  const checkLicense=()=>{
    //prevent working from localhost
    if(window.location.hostname !=='localhost'){
        return false;
    }else if(String(LocalUrl) ===String(window.location.hostname)){
        return true;
      }else{
        return false;
      }
    }
    


    return (<>
  
<a href="#!" data-toggle="modal" id='openLicenseModal' data-target='#licenseModal' ></a>
    <LicenseTimeout   /> 
    <ReactNotification />

   <Router  >
    <Switch>

    <Route exact path="/" component={registration} />
    <Route exact path="/applicant_login" component={login} />
    <Route exact path="/account_verify" component={account_verify} />
    <Route exact path="/forgot_password" component={forgotpassword} />
    <Route exact path="/recover_password" component={recover_password} />
    <ProtectedView />

    
    </Switch>
      </Router>
 </>
    );
  }
export default App;
