import React, {useEffect, useState} from 'react';
import {Alerts} from './component/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import dashboard from './main/overview';
import admission_status from './main/admission_status';
import change_password from './main/change_password';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config,  ServerUrl,  studentName} from './component/include'
import {encrypt, decrypt} from './component/authentication'
import preview_application from './main/preview_application';
import overview from './main/overview';
import Dashboard from './main/Dashboard';
import application from './main/application';



const ProtectedView =()=>{

const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();
var applicationID =decrypt(Cookies.get('appid'))
const jwt = decrypt(Cookies.get('apptk'))

fd.append('applicationID', applicationID)
  fd.append('studentName', studentName)
fd.append('jwt', jwt)


    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
			  
        Cookies.set("_tmapp", inOneMinutes, { expires: inOneHours});
				Cookies.set("_btmapp", inSixtyMinutes, { expires: inOneHours });

			Cookies.set('appstnm', encrypt(response.data.studentName), {expires: inOneHours })
			Cookies.set('apptk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			Cookies.set('appid', encrypt(response.data.applicationID),  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

       // window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/applicant_login'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_btmapp'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     

useEffect(()=>{
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_tmapp'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/overview/:code/:type" component={overview} />
      <Route exact path="/preview_application/:code" component={preview_application} />
    <Route exact path="/change_password" component={change_password} />
    <Route exact path="/status" component={admission_status} />
    <Route exact path="/application" component={application} />

      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
