import Cookies from 'js-cookie'
import { APP_KEY } from './include';
var CryptoJS = require('crypto-js')


   export const encrypt=data=>CryptoJS.AES.encrypt(data, APP_KEY).toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, APP_KEY)
  return byte.toString(CryptoJS.enc.Utf8)
  } 
