import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, helpEmail, helpMobile} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Header from '../component/header_no_login'
import Footer from '../component/footer'

 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		email:'',
		password:'',
		remember:false,
		schoolInfo:[],
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(user));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!email){
            errors.email ='Please enter valid email address';
        }
        
         if(!values.password){
            errors.password ='Please enter your password';
        } 
        
        return errors;
        }
        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])
	
	
        	
	function submit(){  
		setNotice({...notice, isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_applicant_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{

	if (response.data.type ==='success'){
			
			//1000 * 60 * SESSION_IDEL_MINUTES
			var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
			var inOneMinutes = Date.now() + 58 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
			
				Cookies.set("_tmapp", inOneMinutes, { expires: inOneHours});
				Cookies.set("_btmapp", inSixtyMinutes, { expires: inOneHours });

			Cookies.set('appstnm', encrypt(response.data.fullname), {expires: inOneHours })
			Cookies.set('apptk', encrypt(response.data.jwt),  {expires: inOneHours }) 
			Cookies.set('appid', encrypt(response.data.code),  {expires: inOneHours })   
			
			Alerts('Welcome!', 'success', response.data.message)
			window.open('/dashboard', '_self')


			}else if (response.data.type ==='error'){
				Alerts('Error!', 'danger', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice, 
				isLoading: false
			}) 
        })     
}



const clearCookies=()=>{

	 Cookies.remove('appstnm')	
  Cookies.remove('apptk')
  Cookies.remove('appid')

  Cookies.remove('_tmapp')
  Cookies.remove('_btmapp')

}



const fetchSchoolInfo = async ()=>{
        
	const fd = new FormData();
	fd.append('schoolDetails', 'schoolDetails')
	let url = ServerUrl+'/fetch_details'
		const {data} = await axios.post(url, fd, config)
		setUser({...user, schoolInfo:data[0]})
}

useEffect(()=>{  
  clearCookies()
  fetchSchoolInfo()
},[]);


        return (<>
         <Preloader />
          <div id="pcoded" className="pcoded load-height">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

        <Header />
		
        </div>

		  <section  className="with-header">
        <div className="container">

        <div style={{height:'60px'}}>
		
		</div>

            <div className="row">
                <div className="col-sm-6">
                    



				<div className="auth-box card bg-success">
		<div className="card-block">
		
			<div className="card-body">

            <div className='text-white'><h4>LOGIN TO CONTINUE YOUR APPLICATION</h4>
			
			<br/>
			<h5 className='text-align'> Here you can Login to complete your application registration</h5>
			<br/>
			<h5 className='text-align'>You will also need to login to check your admission status</h5>
			<br/>
			<h5 className='text-align'>If you need any help, you can chat us or send us a mail via the following</h5>
			<br/><br/>
			<h6>Telephone: {helpMobile}</h6>
			<h6>Email: {helpEmail}</h6>
			</div>

			</div>
			
		
		</div>
                            </div>

                            </div>
                            <div className="col-sm-6">
	<form className="md-float-material form-material">
	



	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">

<div className="text-center">
		  <img src={ImagesUrl+'/about/'+user.schoolInfo.schoolLogo} style={{ maxWidth:'150px', maxHeight:'150px' }}  alt="logo.png" />
		  </div>

					<h3 className="text-center">Login to your Account</h3>
				</div>
			</div>
			<div className="form-group form-primary">
            <label > Email Address: </label>
				<input type="email" id="email" name="email" role={'presentation'} autoComplete='off' value={user.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
				<span className=" form-bar error">{ errors.email}</span>

			</div>
			<div className="form-group form-primary">
            <label > Password: </label>
			<input type="password" id="password" name="password" role={'presentation'} autoComplete='off'  value={user.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} /> 
			<span className=" form-bar error">{errors.password }</span>


			</div>

                <div className="row m-t-25 text-left">
				<div className="col-12">
					<div className="checkbox-fade fade-in-primary d-">
						<label>
							<input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							<span className="text-inverse">Remember me</span>
						</label>
					</div>
					<div className="forgot-phone text-right f-right">
						<a href="/forgot_password" className="text-right f-w-600"> Forgot Password?</a>
					</div>
				</div>
			</div>
			<div className="row m-t-30">
				<div className="col-md-12">

				{notice.isLoading?<button type="button" disabled  className="btn btn-inverse btn-md btn-block waves-effect waves-light text-center m-b-20"> <i className='fa fa-refresh fa-spin'></i> Please wait... </button>:
					<button type="button" onClick={handleSubmit} className="btn btn-inverse btn-md btn-block waves-effect waves-light text-center m-b-20 f-w-600">SIGN IN</button>}


				</div>
			</div>
			<hr/>
			<div className="row">
				<div className="col-md-10">
					<p className="text-inverse text-left"><a href="/" className='btn btn-info'><b className="f-w-600">Start New Application</b></a></p>
				</div>
				<div className="col-md-2">
					
				<img src={ImagesUrl+'/about/kayus.png'} alt="logo" className="img-30" />
				</div>
			</div>
		</div>
                            </div>
                        </form>
                </div>
            </div>
        </div>
    </section>    
    </div>
	
</> 

 );
        
}

export default React.memo(Login)  